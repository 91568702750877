<template>
  <a-card class="p-4">
    <div class="flex flex-col md:flex-row items-center space-y-5 md:space-y-0 md:items-start md:space-x-5">
      <div class="mr-2 mb-2 flex-shrink-0 flex justify-center">
        <a-avatar :text="administrator.name" :picture="administrator.profile_picture ? administrator.profile_picture.split('/').slice(-1)[0] === 'default-profile-picture.png' ? null : administrator.profile_picture : null" size="72px" />
      </div>
      <div class="flex-grow flex items-center md:items-start flex-col">
        <h4 class="text-black font-bold text-lg">
          <router-link :to="{ name: 'administrator_detail', params: { id: administrator.id } }">
            {{ administrator.name }}
          </router-link>
        </h4>
        <p class="text-gray-700">{{ administrator.phone }}</p>
        <p class="text-gray-700">{{ administrator.email }}</p>
      </div>
      <div class="flex align-items-center justify-content-end" v-if="!user.isStudent">
        <button
          :disabled="deleting.started"
          @click="request"
          v-if="administrator.id !== user.id"
          class="text-red-600 disabled:text-gray-400"
          v-tooltip="`Eliminar administrador`">
          <transition name="fade" mode="out-in">
            <a-loader class="h-6 w-6" v-if="deleting.loading" />
            <a-icon name="trash" class="h-6 w-6" v-else />
          </transition>
        </button>
      </div>
    </div>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    administrator: { required: true, type: Object }
  },
  data: () => ({
    deleting: {
      started: false,
      loading: false
    }
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    remove() {
      this.deleting.loading = !this.deleting.loading
      this.$repository.users
        .delete(this.administrator.id)
        .then(() => {
          this.$emit('fetch')
        })
        .finally(() => {
          this.deleting.loading = !this.deleting.loading
          this.deleting.started = false
        })
    },
    request() {
      this.deleting.started = true
      this.$message.warning({
        title: 'Estás por eliminar un administrador',
        message: '¿Estas seguro de querer continuar con esta acción?',
        buttons: ({ close }) => [
          { title: 'Si, estoy seguro', action: () => {
              close()
            this.remove()
          }},
          { title: 'Cerrar', action: () => {
            this.deleting.started = false
            close()
          }},
        ]
      })
    }
  }
}
</script>