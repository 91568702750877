<template>
  <div class="p-1">
    <div class="flex justify-between mb-3 items-center flex-wrap">
      <a-input placeholder="Buscar" v-model="query.search" @search="search({ ...query, search: $event })" is-search />
      <div>
        <a-button as="router-link" v-if="userCanDo('create') && !session.isStudent" :to="{ name: 'create_administrator' }">
          Crear administrador
        </a-button>
      </div>
    </div>
    <div class="flex justify-between mb-3 mt-2 items-end flex-wrap">
      <h3 class="text-2xl">
        {{ query.type === 4 ? 'Asistentes' : 'Administradores' }}
      </h3>
      <div class="flex h-10">
        <a-segmented-control v-model="query.type" :segments="segments" />
      </div>
    </div>
    <template v-if="loading">
      <div class="space-y-4">
        <a-skeleton class="w-full h-20 rounded-2xl" v-for="n in 3" :key="n" />
      </div>
    </template>
    <div v-else-if="!loading && pagination.data.length > 0" class="space-y-4">
      <administrator-card
        v-for="administrator in pagination.data"
        :key="administrator.id"
        @fetch="search({...query})"
        :administrator="administrator" />
    </div>
    <template v-else>
      <div class="flex flex-col space-y-2 p-16">
        <h1 class="text-2xl text-center">
          No hay datos encontrados
        </h1>
        <span class="text-center">
          <button class="text-primary p-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary inline-flex items-center justify-center rounded-full" @click="search(query)">
            Reintentar
          </button>
        </span>
      </div>
    </template>

    <a-paginate
      :current="pagination.current_page"
      :total="pagination.total"
      @page-changed="search({ ...query, page: $event })"
      :per-page="pagination.per_page"
      class="mt-3"/>
<!--    <a-laravel-pagination align="right" :data="pagination" @change="search({ page: $event, ...query })" class="mt-3"/>-->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import AdministratorCard from './components/administrator-card'
export default {
  components: {
    AdministratorCard
  },
  data: () => ({
    segments: [
      { id: 5, title: "Administradores" },
      { id: 4, title: "Asistentes" },
    ],
    control: 0,
    source: [
      { label: 'Teoria del color', value: 1 },
      { label: 'Diseño grafico', value: 2 },
      { label: 'Dibujo', value: 1 },
      { label: 'Base de datos', value: 1 },
      { label: 'Programacion lineal', value: 1 },
      { label: 'Matematica III', value: 1, },
      { label: 'Diseño grafico', value: 1 },
      { label: 'Dibujo', value: 1 },
    ],
    query: {
      limit: 10,
      type: 5,
      administrators: true,
      search: '',
      sort_by: 'name,asc',
      // order_by: 'name,asc'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      pagination: state => state.administrators.pagination,
      loading: state => state.administrators.loading,
      session: state => state.session.user
    }),
    ...mapGetters({
      userCanDo: 'users/userCanDo'
    })
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    ...mapActions({
      search: 'administrators/index',
      deleteAdministrator: 'administrators/delete'
    }),
  },
  watch: {
    'query.enabled'(enabled) {
      this.search({page: this.$route.query.page || 1, ...this.query, enabled})
    },
    'query.type'(val) {
      this.query.administrators = val === 5
    },
    'query.administrators'(type) {
      this.search({page: this.$route.query.page || 1, ...this.query, type: type ? 5 : 4})
    }
  },
  mounted() {
    this.search({ page: this.$route.query.page || 1, ...this.query })
  },
  created() {
    this.setNavbarTitle(['Listado de administradores'])
  }
}
</script>
